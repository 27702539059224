/***********************************************************************
 components.js contains shared UI components, objects, or packages 
 of each page's application of the MPA
************************************************************************/
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'animate.css';
import '../css/common.css';
import menuIcon from '../images/logos/menu-bar.png';
import evpIcon from '../images/logos/evp_icon.jpg';
import clientLogo from '../images/logos/evp_circle.png';
import loading from '../images/animations/loading.gif';
import _ from '../images/_.png';
import Help from '../images/Help.png';
import example1 from '../images/examples/example1.png';
import example2 from '../images/examples/example2.png';

let domain;

if (process.env.ENVIRONMENT === 'production') {
  // In production, use the environment variable
  domain = process.env.APIDOMAIN; 
} else {
  // In development, load from the local file
  const localEnv = require('../../ENV');
  domain = localEnv.domain;
}

function initApp() { // apply element place holders to the main section of the app element
    $('#app').html(`
        <div id="banner"></div>
        <div id="interface" class="mx-3 mt-2 justify-content-center"></div>
    `);
}

function agreement() {
    return `
    <p>
    This Participation Agreement is entered into by and between the student identified below and by the Manara Center for Coexistence and Dialogue ("Manara Center”) (“Sponsor”) and supported EdVenture Partners (“EVP”) (collectively, the "Administering Parties") to be effective as of the date agreed to by student as evidenced by providing their information below and clicking "I Agree."
    </p>
    <p>
    In consideration of my participation in the Building Allies of Coexistence Program (“ALLIES”) (“Contest”), and for other valuable consideration, the receipt and adequacy of which are hereby acknowledged, I agree to the terms and conditions of this Agreement as set forth below.
    </p>
    <ol style="list-style-type: decimal;">
        <li>Official Rules of the Program/Contest: I have read, understand, and agree to the Official Rules of the Program/Contest, a copy of which is attached to this Agreement and incorporated herein by reference. I agree not to influence or attempt to influence the outcome of the Program/Contest other than by participating in the Program/Contest as expressly permitted in the Official Contest Rules.</li>
        <li>Reliance by the Program Sponsor: I acknowledge that the Program/Contest Sponsor requires that at least one member of the team must be a U.S. citizen if they intend to compete in the contest/prize per the Challenge.gov requirements. The Program Sponsor will rely on this Agreement in spending time, money, and effort on the Program/Contest and my participation in it, and that this Agreement, for this and other reasons, cannot be revoked. I further acknowledge that I am taking part in the Program/Contest of my own free will and volition, and this Agreement does not create an "employee-employer" relationship between me, the Program/Contest Sponsor, or any other party.</li>
        <li>Conflicts of Interest: I represent that I am neither an employee or agent of  the Program/Contest Sponsor, its affiliates, or partners, contractual or otherwise, nor the immediate family member (spouse, mother, father, sister, brother, daughter, or son) or member of the same household (whether related or not) of any such employee or agent, and that I have not participated in the Program/Contest on behalf of any such employee or agent.</li>
        <li>Participation in Publicity.
        <ol style="list-style-type: upper-alpha;">
            <li>I agree to participate in publicity engagements, media exposure, interviews, video and/or audio recordings and all other events relevant to the promotion and publicity of the Contest (“Publicity”). I consent to the Client and Administering Parties recording me on videotape, film, audiotape, paper, digital medium, or otherwise, in connection with the Publicity and in connection with any presentation I may make to the Administering Parties and/or the Client or otherwise in connection with the Program.</li>
            <li>I hereby grant to the Administering Parties and the Client unrestricted, perpetual, irrevocable, royalty-free, assignable, sublicensable, worldwide non-exclusive license to use my name, photograph, likeness, voice, biographical and personal background information, and statements, including without limitation, any notes, photograph, film, or video or audio tape that may be taken of me or of such materials whether in connection with the Publicity or in any materials submitted to the Administering Parties or the Client (the foregoing, collectively, the “Personality License”), without further compensation, consideration, or notice or permission to me or to any third party, and to reproduce, copy, modify, create derivative works of, display, perform, publish, exhibit, distribute, transmit or broadcast, publicly or otherwise, and otherwise use and permit to be used the Personality License or any part thereof, whether alone or in combination with other materials (including but not limited to text, data, images, photographs, illustrations, and graphics, video or audio segments of any nature), in any media or embodiment now known or hereafter developed (including but not limited to any format of any computer-based, Internet-based, electronic, magnetic, digital, laser or optical-based media) for any and all purposes, including educational, governmental or commercial purposes, including without limitation marketing, advertising, promotion, publicity, sales, sweepstakes or contest promotions, activities, or materials.</li>
        </ol>
        </li>
        <li>Ownership of Materials.
        <ol style="list-style-type: upper-alpha;">
            <li>I agree that all ideas, concepts, plans, designs, materials, data, and other tangible and intangible property of every nature created by me or with my participation in connection with the Program (collectively, the "Materials") shall be the sole property of EVP. The Client shall provide each team member with a perpetual, royalty-free, worldwide, non-exclusive licence to use, reproduce, adapt, modify, perform, distribute, communicate, and exploit any of the Materials upon formal written request by emailing info@edventurepartners. The Client is in no way obligated to use any submitted Materials.</li>
            <li>With respect to any Materials created by me or with my participation in connection with the Program which are not the sole property of EVP, I hereby assign, transfer, and convey to EVP all right, title, and interest in and to the Materials, including without limitation any and all copyrights, trademarks, tradenames, service marks, patents, and all other intellectual property rights, and rights of reproduction, and the right to secure registrations, renewals, reissuances, continuances, and extensions of these rights. I agree to execute any further documentation necessary for such assignment and transfer. Without limiting the foregoing, any Materials which are eligible for copyright protection in the United States or elsewhere shall be a work made for hire, to the extent applicable, and all rights under copyright shall be owned by EVP.</li>
            <li>I hereby grant to the Client and the Administering Parties the exclusive, unrestricted, perpetual, royalty-free, assignable, sublicensable, worldwide right and license to use the Materials, to the extent not owned by the Client or the Administering Parties, without any further compensation, consideration, or notice to me or to any third party, to reproduce, copy, modify, create derivative works of, display, perform, publish, exhibit, distribute, transmit or broadcast, publicly or otherwise, and otherwise use and permit to be used the Materials or any part thereof, whether alone or in combination with other materials (including but not limited to text, data, images, photographs, illustrations, and graphics, video or audio segments of any nature), in any media or embodiment now known or hereafter developed (including but not limited to any format of any computer-based, Internet-based, electronic, magnetic, digital, laser or optical-based media), for any educational, governmental or commercial purposes, including without limitation marketing, advertising, promotion, publicity, sales, sweepstakes or contest promotions, activities, or materials.</li>
            <li>I represent and warrant that I am the sole author and creator of all Materials in connection with my participation in the Program. I further represent and warrant that the Materials (i) have not been previously published in any form, (ii) do not infringe upon the intellectual property (including without limitation any copyright, trademark, right of publicity or right of privacy) or other proprietary rights of, or give rise to any claim by, any third party, and (iii) that I have not entered into any agreement with any third party which conflicts with the full assignment of rights granted hereunder. I further acknowledge and agree that neither of the Client, the Administering Parties, nor any third party shall be obligated to exercise any of the rights or licenses granted under this Agreement.</li>
        </ol>
        </li>
        <li>Release; Indemnity:
        <ol style="list-style-type: upper-alpha;">
            <li>I represent that am are fully aware of the risks and hazards inherent in participating in the Program and hereby elect voluntarily to participate in the Program. I hereby voluntarily assume all risk of loss, damage or injury that I may sustain, and any damage to property, arising directly or indirectly as a result of the Program I, ON BEHALF OF MYSELF, MY HEIRS, EXECUTORS, ADMINISTRATORS AND ASSIGNS, AND ANY OTHER PERSON(S) WHO MAY TAKE BY OR THROUGH ME, HEREBY RELEASE, FOREVER DISCHARGE AND AGREE TO HOLD HARMLESS EVP AND CLIENT, AND ALL OF THEIR RESPECTIVE PARENT COMPANIES, SUBSIDIARIES, AFFILIATES, ADVERTISING AND PROMOTIONAL AGENCIES, AND EACH OF THEIR OFFICERS, DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS, EMPLOYEES, AGENTS, AND ADVISORS (COLLECTIVELY, “RELEASED PARTIES”), AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS, FROM AND AGAINST ANY LIABILITIES, OBLIGATIONS, CLAIMS, DEMANDS, DAMAGES, LOSSESS, COSTS AND EXPENSES, OF ANY NATURE WHATSOEVER, WHICH I MAY NOW OR HEREAFTER BE ENTITLED TO ASSERT, INCLUDING BUT NOT LIMITED TO (A) ANY DEATH, INJURY, LOSS OF ENJOYMENT, PROPERTY DAMAGE, OR OTHER HARM OR LOSS OF ANY NATURE, AND (B) ANY CLAIMS BASED ON PUBLICITY RIGHTS, PRIVACY RIGHTS, PERSONALITY RIGHTS, “MORAL RIGHTS,” OR DEFAMATION, AND (C) ANY AND ALL TAXES LEVIED, ASSESSED OR COLLECTED; AND IN EACH OF THE FOREGOING CASES, WHETHER CAUSED BY, CONTRIBUTED TO, ARISING OUT OF, OR OTHERWISE RELATED TO THE CONTEST, MY PARTICIPATION IN THE CONTEST, ANY RELATED TRAVEL, ACCOMMODATIONS, GOODS OR SERVICES PROVIDED TO ME OR TO OTHERS.</li>
            <li>I hereby agree to indemnify, defend and hold harmless the Released Parties, and each of them, from and against any and all liabilities, obligations, claims, demands, damages, losses, costs and expenses (including attorneys' fees and costs at trial and on appeal) arising out of or in connection with (i) my participation in the Program, (ii) any breach of the representations and warranties under this Agreement, and (iii) any death or personal injury to, or damage to property owned by, any third party, except to the extent caused by the gross negligence or willful misconduct of any Administering Party or the Client.</li>
            <li> I expressly agree that the foregoing release, waiver and indemnity is intended to be as broad and inclusive as is permitted by the law of the District of Columbia and that if any portion thereof is held invalid, it is agreed that the balance shall, notwithstanding, continue in full legal force and effect.</li>       
        </ol>
        </li>
        <li>Confidentiality. I agree not to directly or indirectly disclose or use any confidential information or proprietary data with respect to the Administering Parties or the Client that is not otherwise in the public domain, including, but not limited to, confidential information and proprietary data consisting of or contained in or otherwise relating to: marketing strategy information and data; product data; supplier data; business plans; budgets; marketing data; financial data; sales information; cost information; confidential personnel information; or data concerning methods, forms and contracts used by the Administering Parties or the Client.</li>
        <li>Governing Law; Regardless of where or in what sequence this Agreement is executed, it shall be deemed to be made and entered into in the United Arab Emirates (UAE) and shall be governed by, and construed in accordance with, the laws of the UAE applicable to agreements made and entirely performed in the UAE by UAE residents without reference to conflicts of laws principles or jurisprudence.</li>
        <li>Miscellaneous.
        <ol style="list-style-type: upper-alpha;">
            <li>I hereby represent and warrant that all statements made by me in this Agreement are true, accurate, and complete in all respects, and I hereby indemnify the Released Parties from and against any and all losses or damages (including attorneys’ fees) arising out of or relating to any statements or representations made by me in this Agreement or otherwise in connection with the Program. I agree to return immediately upon demand to the Administering Parties or the Client the value of any prize that has been or may be awarded to me if any statement or representation made by me in this Agreement is discovered or believed to be false or misleading, and I acknowledge and agree that all available legal and equitable remedies may be pursued against me in connection therewith.</li>
            <li>I represent and affirm that this Agreement does not conflict with any other commitments or obligations on my part. I agree that this Agreement supersedes all prior negotiations and understandings between myself, the Client, and the Administering Parties relating to the rights granted herein and no provision of this Agreement can be modified by any other instrument or document unless in writing and signed by me and the Administering Parties. Any term or provision of this Agreement which is prohibited, invalid or unenforceable shall be ineffective to the extent (but only to the extent) of such prohibition, invalidity or unenforceability without invalidating or affecting any other term or provision hereof.</li>
            <li>I agree that as part of the Program, I will receive email communications from the Administering Parties relating to Program updates, announcements, and other opportunities and materials. I acknowledge I was provided access to the Privacy Policy prior to completing program registration. At the Program completion I may opt out of receiving future communications from the Administering Parties by following instructions in the message received or by emailing <a href="mailto:support@edventurepartners.com" target="_blank" rel="nofollow">support@edventurepartners.com</a>.</li>
            <li>This Agreement may be executed in one or more counterparts, all of which shall be considered one and the same agreement. Any signature page delivered electronically or by facsimile (including transmission by Portable Document Format or other fixed image form) shall be binding to the same extent as an original signature page.</li>
        </ol>
        </li>
    </ol>
    `;
}

function announcement() {
    return `<div id="announcement" class="w-80 m-5">
    <p>Welcome to the Manara Building Bridges for Coexistence Program! Thank you for your participation in the Manara Youth Coexistence Initiative, empowering young people to foster coexistence within their communities and across regions.</p>
    <p>As we embark on this project, please note that this program portal will be your primary guide. You may find all essential resources, deadlines, and submission requirements as you navigate through the steps on this website. Located in Step 1, the Project Brief is the most important document to familiarize yourself with the program. 
    </p>
    <p>
    To get started, please review the following 3 action items:
    </p>
    <ul style="list-style-type: disc;">
        <li><b>Register:</b> Each student will need to register for the program. If you have not yet registered, please be sure to do so now by going to Step 1 > Registration. Registration is a crucial and mandatory task confirming your participation in the program. </li>
        <li><b>Complete Survey:</b> Please take 5 minutes to take the Manara Youth Initiative Pre-Program Survey, found in Step 1 > Pre-survey.</li>
        <li><b>Assign Team Roles: </b>There are three critical team roles that must be assigned. Once you have decided on these please email your Project Manager the name(s) of the student(s) fulfilling each role. Please note that these can be different individuals fulfilling these roles or one student taking on multiple responsibilities if they are capable of doing so.</li>
        <ul style="list-style-type: disc;">
        <li><i>Team Leader</i>: This will be the student contact for the team. This individual should be highly motivated, organized, and willing to take ownership of leading the team throughout the term. This is the student I will work closely with on project management.</li>
        <li><i>Budget Manager</i>: The Budget Manager will be responsible for filling in the budget request form, managing all program funds, filling out the expense log, and keeping track of all receipts. The Budget Manager can be you or a student who is enthusiastic about the role. The team will receive their funds later in the term via PayPal or paper check upon the completion of the Project Pitch Meeting</li>
        <li><i>Reports Manager</i>: his individual will be in charge of fulfilling the reporting requirements on behalf of the team. They will be tasked with program status reports and ensuring all of the deliverables have been submitted.</li>
        </ul>
    </ul>
    <p>
    <b>Note:</b> If PayPal is not an option in your country, wire transfers can take up to 3 weeks to process in full, therefore. Please notify me of your budget manager as soon as possible to begin the process of disbursing your $1,000 budget. 
    </p>
    <p>
    As your Program Manager, my goal is to ensure that you have everything needed for successful completion of your projects. I look forward to supporting and guiding you throughout this journey. Please feel free to follow up with any questions you may have. In closing, I am honored to be working with you on this important initiative. I look forward to seeing what great work your team will do. 
    </p>
    <p>Warmly,</p>
    <p>Castalia Vidaurri<br />Program Manager for Manara Youth Coexistence Initiative</p>
    </div>`;
}

function navbar() {
    $('#navigation').html(`
    <nav class="navbar navbar-dark bg-secondary" data-bs-theme="secondary">
        <div class="container-fluid">
            <a class="navbar-brand me-auto" href="index.html">
                <img src="${clientLogo}" alt="Logo" width="68" class="d-inline-block align-text-top rounded">
            </a>
            <button class="navbar-toggler collapsed" id="burger-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav"></ul>
            </div>
        </div>
    </nav>
    `);
}

function navItems() {
    $('.navbar-nav').html(`<li class="nav-item ms-auto"><a class="nav-link active" aria-current="page" href="/">Home</a></li>`);
    $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link active" aria-current="page" href="timeline.html">Timeline</a></li>`);
    $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link active" href="team.html">Meet the Team</a></li>`);
    /* if(page !== "benefits") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Benefits of Participating</a></li>`);
    if(page !== "whois-evp") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Who is EVP</a></li>`);
    if(page !== "client") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Your Client</a></li>`);
    if(page !== "research") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Research Material</a></li>`);
    if(page !== "process") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">The Process</a></li>`);
    $('.navbar-nav').append(`
        <li class="nav-item ms-auto dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" id="navbarDropdown" aria-expanded="false">
            Reach the experts
            </a>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Project Manager #1</a></li>
                <li><a class="dropdown-item" href="#">Project Manager #2</a></li>
            </ul>
        </li>
    `); */
    burgerIcon();
}

/* Custom Menu Icon for the navigation */
function burgerIcon() {
    $('#burger-icon').html(`<img class="menu-icon" src="${menuIcon}">`);
}

/* Custom Foot for the portal */
function footer() {
    const year = new Date().getFullYear();
    $('#footer').html(`
    <footer class="p-3 mt-5 text-muted border-top text-center">
        <div id="logo-container"></div>
        <div>© <a class="link" href="https://edventurepartners.com">EdVenture Partners</a> ${year}</div>
        <div><a class="link" href="privacy_policy">Privacy Policy</a></div>
        <!-- <div>developed by <a class="link" href="https://www.github.com/chizuo">Jonathan Chua</a></div> -->
    </footer>
    `);
}

function interfaceLoading() {
    $('#interface').html(`<center><img id="floating-animation" src="${loading}"></center>`);
}

function pdf(path) {
    return `
    <div class="pdf-viewer animate__animated animate__zoomIn zoom">
        <object data="${path}" type="application/pdf" width="100%" height="100%">
            <p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${path}">Download PDF</a></p>
        </object>
    </div>
    `;
} 

function previous(view) {
    $('#return').on('click', view);
}

function returnButton() {
    return `<div class="text-center"><button id="return" class="m-2 btn btn-primary w-25"><i class="bi bi-arrow-left"></i> Return</button></div>`
};

function buttonFactory(elementID, buttonText, type='primary', elementType='div') {
    const element = elementType === "div" ? `<div class="text-center"><button id="${elementID}" class="m-5 btn btn-${type} w-25">${buttonText}</button></div>` : `<span class="text-center"><button id="${elementID}" class="m-5 btn btn-${type} w-25">${buttonText}</button></span>`
    return element;
}

function iconFactory(elementID, iconText, count) {
    let image = count === 1 ? example1 : example2;
    console.log(image);
    return `<div class="col-sm-4 g-0 m-2 animate__animated animate__lightSpeedInLeft icon-15 glow-button">
        <div id="${elementID}" class="square-container-normal">
            <img class="fill icon-15 dark-33" src="${image}">
            <div class="overlay-text text-center display-4">${iconText}</div>
        </div>
    </div>`
}

function helpElement(direction = 'left') {
    let animation = direction == 'left' ? 'animate__backInLeft' : 'animate__backInRight';
    return `<div id="help" class="col-sm-4 g-0 m-2 animate__animated ${animation} icon-15 glow-button ${direction}">
                <div class="square-container-normal">
                    <img class="fill icon-15" src="${Help}"> 
                </div>
            </div>`;
}

function init(participant, main, location) {
    if(participant === null) {
        window.location.href = 'login';
    } else if(participant.tracked.step1["Watch kickoff video"] === 0 && participant.type == "student" && location !== "homepage") {
        window.location.href = '/';
    } else {
        initApp();
        main();
    }
}

export {
    $,
    agreement,
    announcement,
    navbar,
    navItems,
    footer,
    evpIcon,
    domain,
    loading,
    helpElement as Help,
    iconFactory,
    init,
    initApp,
    interfaceLoading,
    description,
    pdf,
    previous,
    resource,
    returnButton,
    buttonFactory,
};
